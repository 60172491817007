import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  BooleanInput,
  DateInput,
  Edit,
  FormWithRedirect,
  NumberInput,
  SaveButton,
  SelectInput,
  TextInput,
  useDataProvider,
  useNotify,
  useRedirect,
  useRefresh,
  usePermissions,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ListIcon from '@material-ui/icons/List';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Box from '@material-ui/core/Box';
import { Link } from '@material-ui/core';
import MuiTextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import MuiAlert from '@material-ui/lab/Alert';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { FileCopy } from '@material-ui/icons';
import { formatChoices, GENDER, PAYMENT_TYPE, RESIDENCE_DURATION, SOURCE_OF_INCOME } from '../../../utils/dictionary';
import { phoneNumber } from '../../../utils/adapter';
import { nameTransform } from '../../../utils/formatter';
import ChangePhoneNumberDialog from '../../dialog/ChangePhoneNumberDialog';
import OldPhonesDialog from '../../old_phones/OldPhonesDialog';
import Call from '../../call/Call';
import wammchat from '../../../img/wammchat.png';
import { useHandbook } from '../../../hooks/useHandbook';
import validateCardNumber from '../../../utils/validateCardNumber';
import UserLoanApplicationDialog from '../../dialog/UserLoanApplicationDialog';
import BlackList from '../../black_list/BlackList';
import BlackListMatch from '../../black_list/BlackListMatch';
import bankNameByAccountNumber from '../../../utils/bankNameByAccountNumber';
import { clabeValidator } from '../../../utils/validator';
import copyToClipboard from '../../../utils/copyToClipboard';
import WebitelButton from '../../webitel_button/WebitelButton';
import ImageZoom, { findImage } from './ImageZoom';
import UserEditTransitions from './UserEditTransitions';
import UserEditAdditionalInfo from './UserEditAdditionalInfo';
import UserTags from './UserTags';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  uploadIcon: {
    marginBottom: '-20px',
    marginTop: '-24px',
  },
  hidden: {
    display: 'none',
  },
  my20: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  paperRed: {
    width: '100%',
    textAlign: 'left',
    padding: theme.spacing(2),
    backgroundColor: '#f50057',
    '&:hover': {
      backgroundColor: '#f50057',
    },
  },
  paperOrange: {
    width: '100%',
    textAlign: 'left',
    padding: theme.spacing(2),
    backgroundColor: '#f36039fa',
    '&:hover': {
      backgroundColor: '#f36039fa',
    },
  },
  texticon: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > div': {
      paddingRight: '0.5rem',
    },
    color: 'white',
  },
  call: {
    textAlign: 'center',
    display: 'flex',
  },
  wammChatLink: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  wammchatImg: {
    width: 30,
    height: 30,
    cursor: 'pointer',
    marginRight: theme.spacing(2),
  },
  flexCenteredSpaced: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  mb3: {
    marginBottom: theme.spacing(3),
  },
}));

const UserEditForm = props => {
  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();
  const classes = useStyles();
  const { permissions = [] } = usePermissions();
  const dataProvider = useDataProvider();

  const [bankAccountState, setBankAccountState] = useState(null);

  const [lastUploadTimeSelfie, setLastUploadTimeSelfie] = useState(null);
  const [lastUploadTimeIdCard, setLastUploadTimeIdCard] = useState(null);
  const [lastUploadTimeIdCardBack, setLastUploadTimeIdCardBack] = useState(null);

  const [oldPhonesDialog, setOldPhonesDialogOpen] = useState(false);
  const [isChangePhoneNumberDialogOpened, setIsChangePhoneNumberDialogOpened] = useState(false);
  const [isChangeSecondPhoneNumberDialogOpened, setIsChangeSecondPhoneNumberDialogOpened] = useState(false);
  const [dndApplying, setDndApplying] = useState(false);
  const [doNotDisturbUser, setDoNotDisturbUser] = useState(false);

  const [totalSelfies, setTotalSelfies] = useState(0);
  const [totalIdFront, setTotalIdFront] = useState(0);
  const [totalIdBack, setTotalIdBack] = useState(0);

  const [isLoanApplicationDialogOpened, setIsLoanApplicationDialogOpened] = useState(false);

  const isEditable = permissions.includes('CAN_USER_EDIT');
  const canPhoneEdit = permissions.includes('CAN_PHONE_NUMBER_EDIT');
  const canUserProfileNicEdit = permissions.includes('CAN_USER_PROFILE_NIC_EDIT');
  const canBankAccountEdit = permissions.includes('CAN_USER_PROFILE_BANK_EDIT');
  const canApplicationEdit = permissions.includes('CAN_APPLICATION_EDIT');

  const { choices: educationChoices } = useHandbook('education');
  const { choices: maritalStatusChoices } = useHandbook('marital_statuses');
  const { choices: employmentTypeChoices } = useHandbook('employments');
  const { choices: contactRelationshipChoices } = useHandbook('phone_relations');
  const { choices: residentTypeChoices } = useHandbook('resident_types');

  React.useEffect(() => {
    props?.record?.bank_account_number && setBankAccountState(clabeValidator(props?.record?.bank_account_number));
  }, [props?.record?.bank_account_number, setBankAccountState]);

  React.useEffect(() => {
    props?.record?.do_not_disturb && setDoNotDisturbUser(props.record.do_not_disturb);
  }, [props?.record?.do_not_disturb]);

  const validateBankAccount = e => setBankAccountState(clabeValidator(e.target.value));

  const validate = values => {
    const errors = {};

    if (values.curp && values.curp.length !== 18) {
      errors.curp = 'CURP length is 18 symbols';
    }

    if (values.card_number) {
      const cardNumberDigits = `${values.card_number}`.replace(/\D/, '');

      if (cardNumberDigits.length < 12 || cardNumberDigits.length > 19) {
        errors.card_number = 'Card number length should be 12-19 symbols';
      } else if (validateCardNumber(cardNumberDigits) === false) {
        errors.card_number = 'Invalid card number';
      }
    }

    return errors;
  };

  const submitPhone = (phone_number, formProps) => {
    const resource = 'users/' + formProps.record.id + '/phone_number';
    dataProvider
      .fetch(resource, {
        body: JSON.stringify(phone_number),
        method: 'PATCH',
      })
      .then(() => {
        notify('Success: Phone number was updated', 'success');
        setIsChangePhoneNumberDialogOpened(false);
        refresh();
      })
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  const submitSecondPhone = (second_phone_number, formProps) => {
    const resource = 'users/' + formProps.record.id + '/second_phone_number';
    dataProvider
      .fetch(resource, {
        body: JSON.stringify(second_phone_number),
        method: 'PATCH',
      })
      .then(() => {
        notify('Success: Phone number was updated', 'success');
        setIsChangeSecondPhoneNumberDialogOpened(false);
        refresh();
      })
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  const buildHandleUploadFile = (path, refreshHandler) => {
    return e => dataProvider.postImage(path, e.target.files[0]).then(() => refreshHandler(Date.now()));
  };

  const handleDNDUser = e => {
    setDoNotDisturbUser(e.target.checked);
    setDndApplying(true);
    dataProvider
      .query(`users/${props.record.id}`, {
        method: 'PATCH',
        body: JSON.stringify({ do_not_disturb: e.target.checked }),
      })
      .then(({ data }) => {
        setDoNotDisturbUser(data?.do_not_disturb);
        notify('Changes succesfully applied', 'success');
      })
      .catch(error => {
        setDoNotDisturbUser(props.record?.do_not_disturb);
        notify(`Error: ${error.message}`, 'error');
      })
      .finally(() => setDndApplying(false));
  };

  const copyText = async text => {
    try {
      await copyToClipboard(text);
      notify('Text copied to clipboard!', { type: 'success' });
    } catch (error) {
      notify(`Error: ${error}`, { type: 'error' });
    }
  };

  return (
    <>
      <FormWithRedirect
        {...props}
        validate={validate}
        render={formProps => (
          <>
            {isEditable ? (
              <>
                <UserEditTransitions
                  userId={formProps.record.id}
                  transitions={formProps.record.enabled_transitions}
                  refresh={refresh}
                  moratoriumTill={formProps.record.moratorium_till}
                  {...formProps.record}
                />
              </>
            ) : null}
            <UserEditAdditionalInfo record={formProps.record} />
            <Divider className={classes.my20} />
            {!formProps.record.is_in_black_list && formProps.record.is_find_in_black_list && (
              <BlackListMatch
                findInBlackList={formProps.record.find_in_black_list}
                username={formProps.record.username}
              />
            )}
            {formProps.record.is_in_black_list && (
              <BlackList
                createdBy={formProps.record.black_list.createdBy}
                createdAt={formProps.record.black_list.createdAt}
                comment={formProps.record.black_list.comment}
                reason={formProps.record.black_list.reason}
              />
            )}
            {formProps.record.state === 'blocked' && (
              <MuiAlert elevation={6} variant="filled" severity="error" className={classes.mb3}>
                User is blocked!
              </MuiAlert>
            )}
            <form>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <Paper className={classes.paper}>
                    <Typography className={classes.wammChatLink} variant="h6" gutterBottom={false}>
                      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Link href={formProps.record.wamm_chat_uri} target="_blank">
                          <img src={wammchat} alt="wammchat_logo" className={classes.wammchatImg} />
                        </Link>
                        Profile
                      </Box>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={doNotDisturbUser}
                            onChange={handleDNDUser}
                            name="checkedDndUSer"
                            color="primary"
                          />
                        }
                        disabled={dndApplying}
                        label="Do not disturb this user"
                      />
                    </Typography>
                    <Divider />
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextInput
                          disabled={!isEditable}
                          format={v => nameTransform(v)}
                          source="first_name"
                          fullWidth
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextInput
                          disabled={!isEditable}
                          format={v => nameTransform(v)}
                          source="last_name"
                          fullWidth
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextInput
                          disabled={!isEditable}
                          format={v => nameTransform(v)}
                          source="father_surname"
                          fullWidth
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextInput
                          disabled={!isEditable}
                          format={v => nameTransform(v)}
                          source="mother_surname"
                          fullWidth
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <TextInput
                          disabled
                          source="phone_number"
                          fullWidth
                          helperText={false}
                          placeholder={phoneNumber.placeholder}
                          parse={phoneNumber.parser}
                          format={value => phoneNumber.formatter(value)}
                        />
                        <ButtonGroup>
                          <Button
                            variant="text"
                            color="primary"
                            size="small"
                            onClick={() => {
                              setOldPhonesDialogOpen(true);
                            }}>
                            Old phone numbers
                          </Button>
                          <Button
                            variant="text"
                            color="secondary"
                            size="small"
                            onClick={() => {
                              setIsChangePhoneNumberDialogOpened(true);
                            }}
                            disabled={!canPhoneEdit}>
                            Change
                          </Button>
                        </ButtonGroup>
                        <OldPhonesDialog
                          userId={formProps.record.id}
                          isOpen={oldPhonesDialog}
                          onClose={setOldPhonesDialogOpen}
                        />
                        <ChangePhoneNumberDialog
                          fieldName="phone_number"
                          isOpened={isChangePhoneNumberDialogOpened}
                          onClose={() => setIsChangePhoneNumberDialogOpened(false)}
                          onSubmit={phone_number => submitPhone(phone_number, formProps)}
                        />
                      </Grid>
                      <Grid item xs={1} style={{ paddingTop: 16 }}>
                        <Box className={classes.flexCenteredSpaced}>
                          <WebitelButton userPhone={formProps.record.username} />
                          <Call
                            inactive={formProps.record.has_sold_loans}
                            userId={formProps.record.id}
                            userPhone={formProps.record.username}
                            hidden
                            edge={'start'}>
                            {'+52' + formProps.record.phone_number}
                          </Call>
                        </Box>
                      </Grid>
                      <Grid item xs={5}>
                        <TextInput
                          disabled
                          source="second_phone_number"
                          fullWidth
                          helperText={false}
                          placeholder={phoneNumber.placeholder}
                          parse={phoneNumber.parser}
                          format={value => phoneNumber.formatter(value)}
                        />
                        <ButtonGroup>
                          <Button
                            variant="text"
                            color="secondary"
                            size="small"
                            onClick={() => {
                              setIsChangeSecondPhoneNumberDialogOpened(true);
                            }}
                            disabled={!canPhoneEdit}>
                            Change
                          </Button>
                        </ButtonGroup>
                        <ChangePhoneNumberDialog
                          fieldName="second_phone_number"
                          isOpened={isChangeSecondPhoneNumberDialogOpened}
                          onClose={() => setIsChangeSecondPhoneNumberDialogOpened(false)}
                          onSubmit={second_phone_number => submitSecondPhone(second_phone_number, formProps)}
                        />
                      </Grid>
                      <Grid item xs={1} style={{ paddingTop: 16 }}>
                        <Call
                          inactive={formProps.record.has_sold_loans}
                          userId={formProps.record.id}
                          userPhone={formProps.record.second_phone_number}
                          hidden
                          edge={'start'}>
                          {'+52' + formProps.record.second_phone_number}
                        </Call>
                      </Grid>
                      <Grid item xs={6}>
                        <TextInput
                          disabled={!isEditable}
                          label="Email Address"
                          source="email"
                          type="email"
                          fullWidth
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextInput
                          disabled={!isEditable || (!canUserProfileNicEdit && !(!props.record.curp && !isEditable))}
                          source="curp"
                          fullWidth
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextInput
                          disabled={!isEditable || (!canUserProfileNicEdit && !(!props.record.rfc && !isEditable))}
                          source="rfc"
                          fullWidth
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextInput
                          disabled={!isEditable || (!canUserProfileNicEdit && !(!props.record.nss && !isEditable))}
                          source="nss"
                          fullWidth
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <SelectInput
                          disabled={!isEditable}
                          label="Gender"
                          source="gender"
                          fullWidth
                          helperText={false}
                          choices={formatChoices(GENDER)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <DateInput
                          disabled={!isEditable}
                          label="Birthday"
                          source="birthday"
                          fullWidth
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <SelectInput
                          key={`marital-statuses-input-${maritalStatusChoices.length}`}
                          disabled={!isEditable}
                          label="Marital status"
                          source="marital_status"
                          fullWidth
                          helperText={false}
                          choices={maritalStatusChoices}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextInput disabled source="nationality" fullWidth helperText={false} />
                      </Grid>
                      <Grid item xs={6}>
                        <BooleanInput
                          disabled={!isEditable}
                          label="Has external loan?"
                          source="has_external_loan"
                          fullWidth
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <NumberInput
                          disabled={!isEditable}
                          label="Monthly payments"
                          source="external_loan_monthly_payments"
                          fullWidth
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <SelectInput
                          disabled={!isEditable}
                          label="Main source of income"
                          source="main_source_of_income"
                          fullWidth
                          helperText={false}
                          choices={formatChoices(SOURCE_OF_INCOME)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <NumberInput
                          disabled={!isEditable}
                          label="Monthly income"
                          source="monthly_income"
                          fullWidth
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <SelectInput
                          key={`educations-input-${educationChoices.length}`}
                          disabled={!isEditable}
                          label="Education"
                          source="education"
                          fullWidth
                          helperText={false}
                          choices={educationChoices}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextInput size="small" label="Affiliate" source="affiliate" disabled fullWidth />
                      </Grid>
                      <Grid item xs={11}>
                        <TextInput
                          multiline
                          size="small"
                          label="User target URL"
                          source="target_url"
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          disabled={!props.record.target_url}
                          onClick={() => copyText(props.record.target_url)}>
                          <FileCopy />
                        </IconButton>
                      </Grid>
                      <Grid item xs={6}>
                        <TextInput
                          multiline
                          size="small"
                          label="Preferred product code"
                          source="preferred_product_code"
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <BooleanInput
                          disabled={!isEditable}
                          label="Consent given?"
                          source="is_data_processing_consent_given"
                          fullWidth
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={6} style={{ pointerEvents: 'none' }}>
                        <BooleanInput
                          disabled={false}
                          label="Allow to receive marketing email"
                          source="is_allow_to_receive_marketing_email"
                          fullWidth
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={6} style={{ pointerEvents: 'none' }}>
                        <BooleanInput
                          disabled={false}
                          label="Allow to receive collection email"
                          source="is_allow_to_receive_collection_email"
                          fullWidth
                          helperText={false}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Paper className={classes.paper}>
                        <Typography variant="h6" gutterBottom={false}>
                          Residence
                        </Typography>
                        <Divider />
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <TextInput
                              disabled
                              label="Country"
                              source="residence_country"
                              fullWidth
                              helperText={false}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextInput
                              disabled={!isEditable}
                              label="Postal code"
                              source="residence_postal_code"
                              fullWidth
                              helperText={false}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextInput
                              disabled={!isEditable}
                              label="City"
                              source="residence_city"
                              fullWidth
                              helperText={false}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextInput
                              disabled={!isEditable}
                              label="Region"
                              source="residence_region"
                              fullWidth
                              helperText={false}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextInput
                              disabled={!isEditable}
                              label="Municipality"
                              source="residence_municipality"
                              fullWidth
                              helperText={false}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextInput
                              disabled={!isEditable}
                              label="Colony"
                              source="residence_colony"
                              fullWidth
                              helperText={false}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextInput
                              disabled={!isEditable}
                              label="Street"
                              source="residence_street"
                              fullWidth
                              helperText={false}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <TextInput
                              disabled={!isEditable}
                              label="External h."
                              source="residence_external_house"
                              fullWidth
                              helperText={false}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <TextInput
                              disabled={!isEditable}
                              label="Internal h."
                              source="residence_internal_house"
                              fullWidth
                              helperText={false}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <TextInput
                              disabled={!isEditable}
                              label="Flat"
                              source="residence_flat"
                              fullWidth
                              helperText={false}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <SelectInput
                              disabled={!isEditable}
                              label="Residence time"
                              source="residence_duration"
                              fullWidth
                              helperText={false}
                              choices={formatChoices(RESIDENCE_DURATION)}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <SelectInput
                              disabled={!isEditable}
                              label="Residence type"
                              source="residence_type"
                              fullWidth
                              helperText={false}
                              choices={residentTypeChoices}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <Paper className={classes.paper}>
                        <Typography variant="h6" gutterBottom={false}>
                          Employment
                        </Typography>
                        <Divider />
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <SelectInput
                              key={`employment-type-input-${employmentTypeChoices.length}`}
                              disabled={!isEditable}
                              label="Employment type"
                              source="employment_type"
                              fullWidth
                              helperText={false}
                              choices={employmentTypeChoices}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <Paper className={classes.paper}>
                        <Typography variant="h6" gutterBottom={false}>
                          STP
                        </Typography>
                        <Divider />
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <TextInput
                              key={'stp-account-number-input'}
                              disabled
                              label="Incoming transactions account number"
                              source="stp_number"
                              fullWidth
                              helperText={false}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                    {permissions.includes('CAN_TAGS_VIEW') && (
                      <Grid item xs={12}>
                        <Paper className={classes.paper}>
                          <Typography variant="h6" gutterBottom={false}>
                            User tags
                          </Typography>
                          <Divider />
                          <UserTags
                            userId={formProps.record.id}
                            tags={formProps.record.tags}
                            editable={permissions.includes('CAN_TAGS_EDIT')}
                          />
                        </Paper>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>
                        <Box className={classes.flexCenteredSpaced}>
                          <Typography variant="h6" gutterBottom={false}>
                            <label htmlFor="selfie_upload">
                              <input
                                disabled={!isEditable}
                                className={classes.hidden}
                                type="file"
                                id="selfie_upload"
                                onChange={buildHandleUploadFile(
                                  `users/${props.record.id}/selfie`,
                                  setLastUploadTimeSelfie,
                                )}
                              />

                              <IconButton
                                disabled={!isEditable}
                                color="primary"
                                aria-label="upload picture"
                                className={classes.uploadIcon}
                                component="span"
                                edge="start">
                                <CloudUploadIcon />
                              </IconButton>
                            </label>
                            Selfie
                          </Typography>
                          {totalSelfies > 0 && (
                            <Typography variant="caption" color="textSecondary">
                              {`${totalSelfies} ${totalSelfies === 1 ? 'file' : 'files'}`}
                            </Typography>
                          )}
                        </Box>
                        {props.record.similarity_card_and_selfie !== null ? (
                          <Typography variant="body1">{`Similarity ${props.record.similarity_card_and_selfie}%`}</Typography>
                        ) : (
                          findImage(props.record.uploaded_files, 'id_card') &&
                          findImage(props.record.uploaded_files, 'selfie') && (
                            <Typography variant="caption" color="error">
                              No face is recognized on the ID card
                            </Typography>
                          )
                        )}
                        <Box pb={1}>
                          <Divider />
                        </Box>
                        <ImageZoom
                          isExist={findImage(props.record.uploaded_files, 'selfie')}
                          userId={props.record.id}
                          label="selfie"
                          lastUploadTime={lastUploadTimeSelfie}
                          handleSetTotal={setTotalSelfies}
                        />
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>
                        <Box className={classes.flexCenteredSpaced}>
                          <Typography variant="h6" gutterBottom={false}>
                            <label htmlFor="id_card_upload">
                              <input
                                disabled={!isEditable}
                                className={classes.hidden}
                                type="file"
                                id="id_card_upload"
                                onChange={buildHandleUploadFile(
                                  `users/${props.record.id}/id_card`,
                                  setLastUploadTimeIdCard,
                                )}
                              />

                              <IconButton
                                disabled={!isEditable}
                                color="primary"
                                aria-label="upload picture"
                                className={classes.uploadIcon}
                                component="span"
                                edge="start">
                                <CloudUploadIcon />
                              </IconButton>
                            </label>
                            Id card front
                          </Typography>
                          {totalIdFront > 0 && (
                            <Typography variant="caption" color="textSecondary">
                              {`${totalIdFront} ${totalIdFront === 1 ? 'file' : 'files'}`}
                            </Typography>
                          )}
                        </Box>
                        <Box pb={1}>
                          <Divider />
                        </Box>
                        <ImageZoom
                          isExist={findImage(props.record.uploaded_files, 'id_card')}
                          userId={props.record.id}
                          label="id_card"
                          lastUploadTime={lastUploadTimeIdCard}
                          handleSetTotal={setTotalIdFront}
                        />
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>
                        <Box className={classes.flexCenteredSpaced}>
                          <Typography variant="h6" gutterBottom={false}>
                            <label htmlFor="id_card_back_side">
                              <input
                                disabled={!isEditable}
                                className={classes.hidden}
                                type="file"
                                id="id_card_back_side"
                                onChange={buildHandleUploadFile(
                                  `users/${props.record.id}/id_card_back_side`,
                                  setLastUploadTimeIdCardBack,
                                )}
                              />

                              <IconButton
                                disabled={!isEditable}
                                color="primary"
                                aria-label="upload picture"
                                className={classes.uploadIcon}
                                component="span"
                                edge="start">
                                <CloudUploadIcon />
                              </IconButton>
                            </label>
                            Id card back
                          </Typography>
                          {totalIdBack > 0 && (
                            <Typography variant="caption" color="textSecondary">
                              {`${totalIdBack} ${totalIdBack === 1 ? 'file' : 'files'}`}
                            </Typography>
                          )}
                        </Box>
                        <Box pb={1}>
                          <Divider />
                        </Box>
                        <ImageZoom
                          isExist={findImage(props.record.uploaded_files, 'id_card_back_side')}
                          userId={props.record.id}
                          label="id_card_back_side"
                          lastUploadTime={lastUploadTimeIdCardBack}
                          handleSetTotal={setTotalIdBack}
                        />
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Paper className={classes.paper}>
                    <Typography variant="h6" gutterBottom={false}>
                      Contact person
                    </Typography>
                    <Divider />
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextInput
                          disabled={!isEditable}
                          label="Name"
                          source="contact_name"
                          fullWidth
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SelectInput
                          key={`contact-relationship-input-${contactRelationshipChoices.length}`}
                          disabled={!isEditable}
                          label="Relationship"
                          source="contact_relationship"
                          fullWidth
                          helperText={false}
                          choices={contactRelationshipChoices}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <TextInput
                          disabled={!isEditable}
                          label="Phone number"
                          source="contact_phone_number"
                          fullWidth
                          helperText={false}
                          placeholder={phoneNumber.placeholder}
                          parse={phoneNumber.parser}
                          format={value => phoneNumber.formatter(value)}
                        />
                      </Grid>
                      <Grid item xs={2} className={classes.call}>
                        <Call
                          inactive={formProps.record.has_sold_loans}
                          userId={formProps.record.id}
                          userPhone={formProps.record.contact_phone_number}
                          hidden
                          edge={'start'}>
                          {'+52' + formProps.record.contact_phone_number}
                        </Call>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Paper className={classes.paper}>
                    <Typography variant="h6" gutterBottom={false}>
                      Payment info
                    </Typography>
                    <Divider />
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <SelectInput
                          disabled={!isEditable || (!canBankAccountEdit && !(!props.record.bank_name && isEditable))}
                          label="Preferred payment type"
                          source="payment_type"
                          fullWidth
                          helperText={false}
                          choices={PAYMENT_TYPE}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextInput
                          disabled={
                            !isEditable || (!canBankAccountEdit && !(!props.record.bank_account_number && isEditable))
                          }
                          label="Card number"
                          source="card_number"
                          fullWidth
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MuiTextField
                          variant="outlined"
                          size="small"
                          label="Bank name"
                          value={
                            props?.record?.bank_account_number
                              ? bankNameByAccountNumber(props?.record?.bank_account_number, 'Unknown')
                              : 'N/A'
                          }
                          disabled
                          fullWidth
                        />
                        <TextInput
                          disabled={
                            !isEditable || (!canBankAccountEdit && !(!props.record.bank_account_number && isEditable))
                          }
                          label="Bank account number"
                          source="bank_account_number"
                          fullWidth
                          helperText={false}
                          onBlur={validateBankAccount}
                        />
                        <FormHelperText error>{bankAccountState}</FormHelperText>
                        <TextInput
                          disabled
                          label="Bank account status"
                          source="bank_account_status"
                          fullWidth
                          helperText={false}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
              <Divider className={classes.my20} />
              <Grid item xs={12}>
                <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                  <Button startIcon={<ListIcon />} onClick={() => redirect('/users')}>
                    Back to list
                  </Button>
                  {(canApplicationEdit &&
                    !formProps.record.is_in_black_list &&
                    !formProps.record.is_find_in_black_list) ||
                  (canApplicationEdit && !formProps.record.is_in_black_list) ? (
                    <Button startIcon={<AddBoxIcon />} onClick={() => setIsLoanApplicationDialogOpened(true)}>
                      Create application
                    </Button>
                  ) : null}
                  {isEditable ? (
                    <SaveButton
                      saving={formProps.saving}
                      handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                      onSuccess={() => notify('Record updated.')}
                    />
                  ) : null}
                </ButtonGroup>
              </Grid>
            </form>
          </>
        )}
      />
      {canApplicationEdit && isLoanApplicationDialogOpened ? (
        <UserLoanApplicationDialog onClose={() => setIsLoanApplicationDialogOpened(false)} record={props.record} />
      ) : null}
    </>
  );
};

UserEditForm.propTypes = {
  record: PropTypes.object,
};

const UserEdit = props => (
  <Edit component="div" mutationMode="pessimistic" {...props}>
    <UserEditForm />
  </Edit>
);

export default UserEdit;
